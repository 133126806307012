// import { writable, derived } from "svelte/store";

export const siteTitle = "ワタシモ - 女性向けの職場クチコミ投稿プラットフォーム";
export function title(pageTitle) {
  if (!pageTitle || pageTitle === "") {
    return siteTitle;
  }
  return `${pageTitle} - ${siteTitle}`;
}

// export const pageTitle = writable("");
// export const title = derived(pageTitle, ($p) => $p == "" ? siteTitle : `${$p} - ${siteTitle}`)
